<template>
<div>
    <b-row>
        <b-col lg="3">
            <CWidgetDropdown color="dark" :header="estadisticos.totalPosiblesClientes" text="Posibles clientes">
                <template #default>

                </template>
                <template #footer>
                    <CChartLineSimple pointed class="mt-3 mx-3" style="height:70px" :data-points="[65, 59, 84, 84, 51, 55, 40]" :options="{tooltips:{enabled:false}}" point-hover-background-color="primary" label="Members" labels="months" />
                </template>
            </CWidgetDropdown>
        </b-col>
        <b-col lg="3">
            <CWidgetDropdown color="secondary" :header="estadisticos.totalClientes" text="Clientes">
                <template #default>

                </template>
                <template #footer>
                    <CChartLineSimple pointed class="mt-3 mx-3" style="height:70px" :data-points="[1, 18, 9, 17, 34, 22, 11]" point-hover-background-color="info" :options="{ elements: { line: { tension: 0.00001 }},tooltips:{enabled:false}}" label="Members" labels="months" />
                </template>
            </CWidgetDropdown>
        </b-col>
        <b-col lg="3">
            <CWidgetDropdown color="danger" :header="estadisticos.totalOfertas" text="Cotizaciones">
                <template #default>

                </template>
                <template #footer>
                    <CChartLineSimple class="mt-3" style="height:70px" background-color="rgba(255,255,255,.2)" :data-points="[78, 81, 80, 45, 34, 12, 40]" :options="{ elements: { line: { borderWidth: 2.5 }},tooltips:{enabled:false}}" point-hover-background-color="info" label="Members" labels="months" />
                </template>
            </CWidgetDropdown>
        </b-col>
        <b-col lg="3">
            <CWidgetDropdown color="info" :header="estadisticos.totalVentas" text="Ventas">
                <template #default>

                </template>
                <template #footer>
                    <CChartBarSimple class="mt-3 mx-3" style="height:70px" background-color="rgb(250, 152, 152)" :options="{tooltips:{enabled:false}}" label="Members" labels="months" />
                </template>
            </CWidgetDropdown>
        </b-col>
        <b-col lg="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-money-bill-alt fa-md mr-1"></i> <span class="h5"> Cotizaciones
                    </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaCotizaciones" :fields="campoCotizaciones" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(total)="param">
                                    <span>S/. {{param.item.total}} </span>
                                </template>
                                <template v-slot:cell(cliente)="data">
                                    {{data.value | truncate(35)}}
                                </template>
                                <template #cell(fechaFinContrato)="param">
                                    <span class="mr-2">{{param.item.fechaFinContrato}}</span>
                                    <span class="h5" v-if="validarFecha(param)">
                                        <b-badge variant="danger">!</b-badge>
                                    </span>
                                </template>
                                <template #cell(acciones)="param">
                                    <b-button class="mr-1 mb-1" size="sm" variant="blue" @click="param.toggleDetails" v-c-tooltip="'Servicios'">
                                        <i :class="`fas fa-eye${param.detailsShowing ? '-slash' : ''} my-0 mx-0`"></i>
                                    </b-button>
                                    <b-button :to="{name:'Gestión de venta master', params: {id: param.item.idVenta}}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button @click="eliminarVenta(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #row-details="row">
                                    <b-row>
                                        <b-col md="3">
                                            <span class="h6"> Servicios contratados: </span>
                                        </b-col>
                                        <b-col md="9">
                                            {{row.item.servicio}}
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-2">
                                        <b-col md="3">
                                            <span class="h6"> Incluye colaborador: </span>
                                        </b-col>
                                        <b-col md="9">
                                            <span v-if="row.item.conColaborador=='2'">SI</span>
                                            <span v-else-if="row.item.conColaborador=='1'">NO</span>
                                        </b-col>
                                    </b-row>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination class="float-right my-0" size="sm" v-model="currentPage" :total-rows="filasTotales" :per-page="porPagina" first-number last-number></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import {
    CChartLineSimple,
    CChartBarSimple
} from '@/Components/charts/index.js'

import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {
        CChartLineSimple,
        CChartBarSimple
    },
    data() {
        return {
            estadisticos: {
                totalPosiblesClientes: "2",
                totalClientes: "3",
                totalOfertas: "2",
                totalVentas: "1",
            },
            campoCotizaciones: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "cliente",
                    label: "Cliente",
                    class: "text-center",
                },
                {
                    key: "fechaVenta",
                    label: "Inicio contrato",
                    class: "text-center",
                },
                {
                    key: "fechaFinContrato",
                    label: "Finalización",
                    class: "text-center",
                },
                {
                    key: "total",
                    label: "Importe",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaCotizaciones: [],

            filasTotales: 0,
            currentPage: 1,
            porPagina: 10,
            filter: null,
            filterOn: [],
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
        }
    },
    methods: {
        validarFecha(param) {
            return moment(param.item.fechaFinContrato).isSameOrBefore(moment().format('YYYY-MM-DD'))
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        listarVentas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/listar-ventas", {
                    params: {
                        activo: 2
                    }
                })
                .then(function (response) {
                    me.listaCotizaciones = response.data;
                    me.listaCotizaciones.sort((a, b) => (a.fechaVenta < b.fechaVenta) ? 1 : ((b.fechaVenta < a.fechaVenta) ? -1 : 0));
                    me.filasTotales = me.listaCotizaciones.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                })
            ;
        },
        contarVentas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/contar-ventas")
                .then(function (response) {
                    me.estadisticos.totalVentas = String(response.data[0].venta);
                    me.estadisticos.totalOfertas = String(response.data[0].oferta);
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        contarClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "master/contar-clientes")
                .then(function (response) {
                    me.estadisticos.totalClientes = String(response.data[0].cliente);
                    me.estadisticos.totalPosiblesClientes = String(response.data[0].posible);
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        eliminarVenta(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la venta?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "master/eliminar-venta", {
                            idVenta: param.item.idVenta
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarVentas();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    mounted() {

    }
}
</script>

<style>

</style>
